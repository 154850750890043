import {
  DataChannelDTO,
  ReadingDTO,
  ForecastReadingsDto,
  IDataChannelService,
  UnitTypeEnum,
  ValueTupleOfDoubleAndDouble,
} from 'api/admin/api';
import { ReadingsChartZoomLevel } from 'types';
import { UnitOfMeasureCategory } from 'utils/i18n/enum-to-text';

export enum ProblemReportStatusFilterEnum {
  Open = 0,
  Closed = 1,
  Both = 2,
}

export enum DaysRunOut {
  All = 0,
  ThreeDays = 3,
  FiveDays = 5,
  TenDays = 10,
}

export enum ChartZoomLevelButtonOption {
  Custom = -1,
  // Use a specific value for the `Now` option
  Now = ReadingsChartZoomLevel.TwelveHours,
  TwelveHours = ReadingsChartZoomLevel.TwelveHours,
  OneYear = ReadingsChartZoomLevel.OneYear,
  ThreeMonths = 2160,
  OneMonth = 720,
  TwelveWeeks = ReadingsChartZoomLevel.TwelveWeeks,
  FourWeeks = ReadingsChartZoomLevel.FourWeeks,
  TwoWeeks = ReadingsChartZoomLevel.TwoWeeks,
  OneWeek = ReadingsChartZoomLevel.OneWeek,
  FourDay = ReadingsChartZoomLevel.FourDays,
  TwoDay = ReadingsChartZoomLevel.TwoDays,
  OneDay = ReadingsChartZoomLevel.OneDay,
}

export enum TimeRangeDropdownOptions {
  Custom = -1,
  // Silverlight goes back 2 months when "None" is selected.
  // To not have both TwoMonths and None selected at the same time, None will
  // be one less day in hours.
  None = 1436,
  TwoHours = 2,
  FourHours = 4,
  TwelveHours = 12,
  OneDay = 24,
  TwoDays = 48,
  FourDays = 96,
  OneWeek = 168,
  TwoWeeks = 336,
  FourWeeks = 672,
  TwoMonths = 1460,
  FourMonths = 2920,
  SixMonths = 4380,
  OneYear = 8760,
}

// Import csv-related types
export interface ValueTupleOfDoubleAndDoubleWithKey
  extends ValueTupleOfDoubleAndDouble {
  // We add a key to prevent weird react .map() issues where stale data is shown
  key: string;
}

export interface CachedDataChannelReadings {
  readings: ReadingDTO[];
  fromDate: Date;
  toDate: Date;
  wereReadingsSummarized?: boolean;
  isFetching: boolean;
  hasError: boolean;
  hasCachedData: boolean;
}

export type DataChannelForGraph = DataChannelDTO;

export interface ForecastReadingsHookApiData {
  isFetching: boolean;
  error: any;
  data: ForecastReadingsDto | null | undefined;
  duration: number | null;
  makeRequest: IDataChannelService['dataChannel_GetForecasts'];
}

export interface ReadingsHookData {
  cachedReadings: Record<string, CachedDataChannelReadings | undefined>;
  cachedForecastReadings: Record<string, ForecastReadingsDto | undefined>;
  isCachedReadingsApiFetching: boolean;
  dataChannelForecastsApi: ForecastReadingsHookApiData;
  clearCache: () => void;
}

export interface GraphProperties {
  zoomLevel: ReadingsChartZoomLevel;
  setZoomLevel: (zoomLevel: ReadingsChartZoomLevel) => void;
  showForecast: boolean;
  setShowForecast: (showForecast: boolean) => void;
  showAllGpsReadings: boolean;
  setShowAllGpsReadings: (showAllGpsReadings: boolean) => void;
  fromDate: Date;
  setFromDate: (date: Date) => void;
  toDate: Date;
  setToDate: (date: Date) => void;
  paddedFromDate: Date;
  paddedToDate: Date;
  graphMinY?: number;
  setGraphMinY: (min?: number) => void;
  graphMaxY?: number;
  setGraphMaxY: (max?: number) => void;
  manyGraphedDataChannels?: DataChannelDTO[];
  setManyGraphedDataChannels: (dataChannels: DataChannelDTO[]) => void;

  // Additional things required for the graph
  initialZoomLevel: ReadingsChartZoomLevel.NotSet | ReadingsChartZoomLevel;
}

interface DataForChangingDataChannelUnit {
  dataChannelId: string;
  unit?: UnitTypeEnum | null;
}

export type ChangeDataChannelToUnitMappingFunction = (
  dataChannelIdsWithUnit: DataForChangingDataChannelUnit[]
) => void;

export type ChangeSelectedDataChannelFunction = (
  dataChannel: DataChannelForGraph,
  checked: boolean
) => void;

export interface CommonGraphDataChannelProps {
  isAssetNavigationDetailsPage?: boolean;
  dataChannels: DataChannelDTO[];
  isPublishedAsset?: boolean;
  selectedDataChannels: DataChannelForGraph[];
  isFetchingDataChannel?: boolean;
  minimumSelectionCount?: number;
  hideUnitOfMeasureButtons?: boolean;
  canSelectDataChannel?: (dataChannel: DataChannelDTO) => boolean;
  handleChangeSelectedDataChannel: ChangeSelectedDataChannelFunction;
  handleChangeDataChannelToUnitMapping?: (
    dataChannelId: string,
    unit?: UnitTypeEnum | null
  ) => void;
}

export interface UOMOption {
  label: string;
  value: any;
  type: UnitOfMeasureCategory;
}

export interface AssetDetailDataChannelAction {
  label: React.ReactNode;
  onClick?: (event: React.MouseEvent, record: DataChannelDTO) => void;
}
