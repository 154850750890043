import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import { Column } from './types';

export const excelExport = async <T>(
  columns: Column[],
  data: T[],
  fileName?: string
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('My Sheet');
  worksheet.columns = columns;

  data.forEach((row) => {
    worksheet.addRow(row);
  });

  const buf = await workbook.xlsx.writeBuffer();

  const blb = new Blob([buf], { type: 'application/vnd.ms-excel' });

  FileSaver.saveAs(blb, fileName || 'default.xlsx');
};
