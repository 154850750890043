import { ReportType } from 'api/admin/api';
import { Column } from 'utils/file/types';

export const ExcelReportColumns = (reportType?: ReportType): Column[] => {
  if (!reportType) {
    return [];
  }

  switch (reportType) {
    case ReportType.LowBatteryVoltage:
      return [
        { header: 'Domain', key: 'domainName' },
        { header: 'Asset', key: 'assetTitle' },
        { header: 'Description', key: 'dataChannelDescription' },
        { header: 'Customer', key: 'customerName' },
        { header: 'RTU', key: 'deviceId' },
        { header: 'Channel', key: 'channelNumber' },
        { header: 'Carrier', key: 'carrier' },
        { header: 'Product', key: 'productName' },
        { header: 'Last Data Received', key: 'lastReadingTimestamp' },
        { header: 'Last Battery Voltage', key: 'latestBatteryVolts' },
        { header: 'Battery Low', key: 'batteryLow' },
      ];
    case ReportType.DataChannelsWithMissingReadings:
      return [
        { header: 'Domain', key: 'domainName' },
        { header: 'Asset', key: 'assetTitle' },
        { header: 'Description', key: 'dataChannelDescription' },
        { header: 'Customer', key: 'customerName' },
        { header: 'Address', key: 'address1' },
        { header: 'City', key: 'city' },
        { header: 'State', key: 'state' },
        { header: 'Country', key: 'country' },
        { header: 'RTU', key: 'deviceId' },
        { header: 'Channel', key: 'channelNumber' },
        { header: 'Carrier', key: 'carrier' },
        { header: 'Product', key: 'productName' },
        { header: 'Last Date Received', key: 'lastReadingTimestamp' },
        { header: 'Expected Date On', key: 'lastReadingExpectedTimestamp' },
        { header: 'Delinquent Period', key: 'carrier' },
      ];
    default:
      return [];
  }
};
