import styled from 'styled-components';
import Img from 'assets/images/dolv3-edge-instructions/3b.png';

const enableReloadSettingsImage = styled.img.attrs({
  src: Img,
  alt: 'Settings menu item',
})`
  max-width: 256px;
`;

export default enableReloadSettingsImage;
