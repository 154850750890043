import { ADMIN_BASE_API_URL, SHOULD_USE_PROXY } from 'env';
import { CustomAxios } from '../custom-axios';
import {
  AssetService,
  AuthenticationService,
  B2CUsersService,
  BaseService,
  ChartDefaultService,
  ChartService,
  DashboardService,
  DataChannelTemplateService,
  DataChannelEventRuleService,
  DataChannelService,
  DolV3AccessForB2CUserService,
  DomainAssetGroupService,
  DomainNotesService,
  DomainRoleService,
  DomainService,
  EventRuleGroupService,
  EventService,
  FileRtuService,
  FreezerAssetService,
  FreezerSiteService,
  GeneralService,
  GeoAreaService,
  GetDataChannelReadingsService,
  HistoricalReadingsService,
  InventoryStatesService,
  LocationReadingService,
  MessageTemplateService,
  ModbusRtuService,
  MetronRtuService,
  ProblemReportService,
  ProductService,
  ReportService,
  RosterService,
  RtuChannelService,
  RTUService,
  RtuService,
  SiteService,
  SmsRtuService,
  StrappingChartService,
  TagService,
  TankDimensionService,
  UnitQuantityTypeService,
  UnitsConverterService,
  UnitTypeService,
  UsageRateService,
  UserDetailsService,
  UserService,
  UserWatchListService,
  HornerRtuService,
  HornerDetailRecordTemplateService,
  HornerTransactionRecordTemplateService,
  DeliveryScheduleService,
  AssetsService,
  PacketService,
  UnitService,
  ReadingsService,
  PresetReportService,
  ReportQueryService,
} from './api';

// Swap between a proxy (usually for local development) or the API url directly
const apiUrl = SHOULD_USE_PROXY ? '/api' : ADMIN_BASE_API_URL;

/**
 * The ApiService that has access to all other services.
 */
const ApiService = {
  AssetService: new AssetService(apiUrl, CustomAxios),
  AssetsService: new AssetsService(apiUrl, CustomAxios), // Asset Navigator
  AuthenticationService: new AuthenticationService(apiUrl, CustomAxios),
  B2CUsersService: new B2CUsersService(apiUrl, CustomAxios),
  BaseService: new BaseService(apiUrl, CustomAxios),
  ChartService: new ChartService(apiUrl, CustomAxios),
  ChartDefaultService: new ChartDefaultService(apiUrl, CustomAxios),
  ModbusRtuService: new ModbusRtuService(apiUrl, CustomAxios),
  MetronRtuService: new MetronRtuService(apiUrl, CustomAxios),
  DashboardService: new DashboardService(apiUrl, CustomAxios),
  DataChannelTemplateService: new DataChannelTemplateService(
    apiUrl,
    CustomAxios
  ),
  DataChannelService: new DataChannelService(apiUrl, CustomAxios),
  DataChannelEventRuleService: new DataChannelEventRuleService(
    apiUrl,
    CustomAxios
  ),
  DolV3AccessForB2CUserService: new DolV3AccessForB2CUserService(
    apiUrl,
    CustomAxios
  ),
  DeliveryScheduleService: new DeliveryScheduleService(apiUrl, CustomAxios),
  DomainAssetGroupService: new DomainAssetGroupService(apiUrl, CustomAxios),
  DomainNotesService: new DomainNotesService(apiUrl, CustomAxios),
  DomainRoleService: new DomainRoleService(apiUrl, CustomAxios),
  DomainService: new DomainService(apiUrl, CustomAxios),
  EventService: new EventService(apiUrl, CustomAxios),
  FileRtuService: new FileRtuService(apiUrl, CustomAxios),
  EventRuleGroupService: new EventRuleGroupService(apiUrl, CustomAxios),
  FreezerAssetService: new FreezerAssetService(apiUrl, CustomAxios),
  FreezerSiteService: new FreezerSiteService(apiUrl, CustomAxios),
  GetDataChannelReadingsService: new GetDataChannelReadingsService(
    apiUrl,
    CustomAxios
  ),
  GeneralService: new GeneralService(apiUrl, CustomAxios),
  GeoAreaService: new GeoAreaService(apiUrl, CustomAxios),
  HistoricalReadingsService: new HistoricalReadingsService(apiUrl, CustomAxios),
  InventoryStatesService: new InventoryStatesService(apiUrl, CustomAxios),
  MessageTemplateService: new MessageTemplateService(apiUrl, CustomAxios),
  LocationReadingService: new LocationReadingService(apiUrl, CustomAxios),
  ProblemReportService: new ProblemReportService(apiUrl, CustomAxios),
  ProductService: new ProductService(apiUrl, CustomAxios),
  ReportService: new ReportService(apiUrl, CustomAxios),
  RosterService: new RosterService(apiUrl, CustomAxios),
  ReadingsService: new ReadingsService(apiUrl, CustomAxios),
  RtuChannelService: new RtuChannelService(apiUrl, CustomAxios),
  RTUService: new RTUService(apiUrl, CustomAxios),
  RtuService: new RtuService(apiUrl, CustomAxios),
  SmsRtuService: new SmsRtuService(apiUrl, CustomAxios),
  SiteService: new SiteService(apiUrl, CustomAxios),
  StrappingChartService: new StrappingChartService(apiUrl, CustomAxios),
  TagService: new TagService(apiUrl, CustomAxios),
  TankDimensionService: new TankDimensionService(apiUrl, CustomAxios),
  UnitQuantityTypeService: new UnitQuantityTypeService(apiUrl, CustomAxios),
  UnitsConverterService: new UnitsConverterService(apiUrl, CustomAxios),
  UnitTypeService: new UnitTypeService(apiUrl, CustomAxios),
  UsageRateService: new UsageRateService(apiUrl, CustomAxios),
  UserDetailsService: new UserDetailsService(apiUrl, CustomAxios),
  UserService: new UserService(apiUrl, CustomAxios),
  UserWatchListService: new UserWatchListService(apiUrl, CustomAxios),
  HornerRtuService: new HornerRtuService(apiUrl, CustomAxios),
  PacketService: new PacketService(apiUrl, CustomAxios),
  UnitService: new UnitService(apiUrl, CustomAxios),
  HornerDetailRecordTemplateService: new HornerDetailRecordTemplateService(
    apiUrl,
    CustomAxios
  ),
  HornerTransactionRecordTemplateService: new HornerTransactionRecordTemplateService(
    apiUrl,
    CustomAxios
  ),
  PresetReportService: new PresetReportService(apiUrl, CustomAxios),
  ReportQueryService: new ReportQueryService(apiUrl, CustomAxios),
};

export default ApiService;
export { ApiService };
