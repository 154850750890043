import routes from 'apps/reports/routes';
import AuthedRoute from 'components/AuthedRoute';
import CommonRoutes from 'components/CommonRoutes';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import QuickTankCreate from './containers/QuickAssetCreateReport';
import ReportsList from './containers/ReportsList';
import NewReportsList from './containers/NewReportsList';

function ReportsApp() {
  return (
    <>
      <Switch>
        <AuthedRoute path={routes.base} exact>
          <Redirect to={routes.reports.list} />
        </AuthedRoute>
        <AuthedRoute path={routes.reports.list} exact>
          <ReportsList />
        </AuthedRoute>
        <AuthedRoute path={routes.newReport.home} exact>
          <NewReportsList />
        </AuthedRoute>
        <AuthedRoute path={routes.newReport.quickAssetCreate} exact>
          <QuickTankCreate />
        </AuthedRoute>
        <CommonRoutes routes={routes} />
      </Switch>
    </>
  );
}

export default ReportsApp;
