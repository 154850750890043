import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import Button, { ButtonProps } from 'components/Button';
import { useRefreshUserPermissions } from 'hooks/useRefreshUserPermissions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledRefreshIcon = styled(RefreshIcon)`
  color: ${(props) =>
    props.theme.palette.type === 'light'
      ? props.theme.custom.domainSecondaryColor
      : props.theme.custom.domainColor};
`;

interface Props extends ButtonProps {
  useIconButton?: boolean;
}

const RefreshButton = ({ onClick, useIconButton, ...buttonProps }: Props) => {
  const { t } = useTranslation();

  const { refetch: refreshPermissions } = useRefreshUserPermissions();

  return useIconButton ? (
    <IconButton
      onClick={(...args) => {
        refreshPermissions();
        onClick?.(...args);
      }}
    >
      <StyledRefreshIcon />
    </IconButton>
  ) : (
    <Button
      variant="text"
      startIcon={<RefreshIcon />}
      useDomainColorForIcon
      {...buttonProps}
      onClick={(...args) => {
        refreshPermissions();
        onClick?.(...args);
      }}
    >
      {t('ui.common.refresh', 'Refresh')}
    </Button>
  );
};

export default RefreshButton;
