import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import UpdatedConfirmationDialog from 'components/dialog/UpdatedConfirmationDialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectShowErrorSwitchDomainDialog } from 'redux-app/modules/app/selectors';
import { setShowErrorSwitchDomainDialog } from 'redux-app/modules/app/actions';

const AppDomainSwitcherFailDialog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const showErrorSwitchDomainDialog = useSelector(
    selectShowErrorSwitchDomainDialog
  );

  const handleConfirm = () => {
    dispatch(setShowErrorSwitchDomainDialog(false));
  };

  const title = t('ui.common.unableswitchdomain', 'Unable to switch domains');
  const actionText = t(
    'ui.common.unableswitchdomainhelper',
    'Please try again.'
  );

  return (
    <UpdatedConfirmationDialog
      open={showErrorSwitchDomainDialog}
      maxWidth="xs"
      disableBackdropClick
      disableEscapeKeyDown
      mainTitle={title}
      content={
        <Grid container spacing={3} alignItems="center" justify="center">
          <Grid item xs={12}>
            <Typography align="center">{actionText}</Typography>
          </Grid>
        </Grid>
      }
      onConfirm={handleConfirm}
      hideCancelButton
    />
  );
};

export default AppDomainSwitcherFailDialog;
