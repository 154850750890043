import React, { Suspense } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { Column } from 'utils/file/types';
import { isValueValid } from 'utils/functions/isValueValid';
import {
  OrderInfo,
  PdfReportColumns,
} from 'apps/ops/containers/AssetSummary/components/QuickReportDownloadDialog/PdfReportColumns';
import { ReportType } from 'api/admin/api';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
  mainsection: {
    flexDirection: 'row',
  },
  section: {
    flexDirection: 'row',
  },
  rowtitle: {
    backgroundColor: '#f5c800',
    flexDirection: 'row',
  },
  head: {
    border: '1px solid black',
    padding: 5,
    fontWeight: 'extrabold',
    fontSize: '8px',
    backgroundColor: '#e0e0e0',
    width: '10%',
  },
  text: {
    border: '1px solid black',
    padding: 5,
    fontSize: '8px',
    width: '10%',
  },
});
const applyFunctionIfAvailable = (item: OrderInfo, value?: any) => {
  if (item.func && isValueValid(value)) {
    return item.func(value);
  }
  if (isValueValid(value)) {
    return value[item.key as keyof typeof value];
  }

  return '';
};
const calculateColumnWidth = (maxSize: number[]): number[] => {
  if (maxSize?.length === 0) return [];

  const total = maxSize?.reduce((acc, item) => {
    const newResult = acc + item;
    return newResult;
  });
  const colWidth = maxSize?.map((item) => {
    return (item / total) * 100;
  });
  return colWidth;
};
type ReportResultStoredProcedure = {
  columns: any[];
  records: any[];
};
type PdfDocumentProps = {
  reportResult: any[];
  quickReportType: ReportType;
};
const NumberOfItemsMessage = ({
  numberOfItems,
  numberOfItemsInReport,
}: {
  numberOfItems: number;
  numberOfItemsInReport: number;
}) => {
  if (numberOfItems <= numberOfItemsInReport) {
    return (
      <View>
        <Text style={{ ...styles.head, width: '100%' }}>
          {`The report has ${numberOfItems} items.`}
        </Text>
      </View>
    );
  }
  return (
    <View>
      <Text style={{ ...styles.head, width: '100%' }}>
        {`The report has ${numberOfItems} items. Only the first ${numberOfItemsInReport} items are displayed.`}
      </Text>
    </View>
  );
};
const numberOfItemsInReport = 500;
const PdfDocument = <T,>({ data }: { data: PdfDocumentProps }) => {
  const numberOfItems = data?.reportResult?.length;

  const dataMemo = React.useMemo(() => {
    return {
      quickReportType: data.quickReportType,
      reportResult: data.reportResult.slice(0, numberOfItemsInReport - 1),
    };
  }, [data]);
  const columnsSettings = PdfReportColumns(dataMemo?.quickReportType);

  const { order, initSize, titleRow, paperSize } = columnsSettings || {};

  if (dataMemo && order && dataMemo?.quickReportType < 1000) {
    const maxSize = dataMemo?.reportResult?.reduce<number[]>(
      (acc: number[], item) => {
        order?.forEach((col, index) => {
          if (
            isValueValid(item[col.key as keyof typeof item]) &&
            String(item[col.key as keyof typeof item]).length > acc[index]
          ) {
            acc[index] = String(item[col.key as keyof typeof item]).length;
          }
        });

        return acc;
      },
      initSize || []
    );
    const colWidth: number[] = calculateColumnWidth(maxSize);
    return (
      <Document>
        <Page
          orientation="landscape"
          size={paperSize || 'A4'}
          style={styles.page}
          wrap
        >
          <View style={styles.mainsection}>
            {order?.map((item, index) => (
              <Text style={{ ...styles.head, width: `${colWidth[index]}%` }}>
                {item.header}
              </Text>
            ))}
          </View>
          {titleRow && (
            <RowWithTitle
              data={dataMemo}
              order={order}
              colWidth={colWidth}
              titleName={titleRow}
            />
          )}
          {!titleRow && (
            <RowWithoutTitle
              data={dataMemo}
              order={order}
              colWidth={colWidth}
            />
          )}
          <NumberOfItemsMessage
            numberOfItems={numberOfItems || 0}
            numberOfItemsInReport={numberOfItemsInReport}
          />
        </Page>
      </Document>
    );
  }
  if (dataMemo?.quickReportType > 1000) {
    const {
      columns,
      records,
    } = (dataMemo?.reportResult as unknown) as ReportResultStoredProcedure;

    const columnsNumber = columns?.length;

    let pageSize: 'A2' | 'A3' | 'A4' = 'A4';

    if (columnsNumber > 30) {
      pageSize = 'A2';
    } else if (columnsNumber > 20) {
      pageSize = 'A3';
    } else {
      pageSize = 'A4';
    }
    return (
      <Document>
        <Page orientation="landscape" size={pageSize} style={styles.page} wrap>
          <View style={styles.mainsection}>
            {columns.map((item, index) => (
              <Text style={{ ...styles.head }} key={index}>
                {item.name}
              </Text>
            ))}
          </View>
          <View>
            <Suspense fallback={<p>Loading...</p>}>
              <StoredProcedureReportRow columns={columns} records={records} />
            </Suspense>
          </View>
          <NumberOfItemsMessage
            numberOfItems={numberOfItems || 0}
            numberOfItemsInReport={numberOfItemsInReport}
          />
        </Page>
      </Document>
    );
  }
  return null;
};
export default PdfDocument;
interface RowWithTitleProccessed {
  title: string;
  data: any[];
}
const RowWithTitle = ({
  data,
  order,
  colWidth,
  titleName,
}: {
  data: PdfDocumentProps;
  order: OrderInfo[];
  colWidth: number[];
  titleName: string;
}): React.ReactElement | null => {
  if (!data || !order) return null;

  const titles = data?.reportResult?.map<string>((d) => d[titleName]);

  const uniqueTitles = titles?.filter((v, i, a) => a.indexOf(v) === i);

  const orderedTitles = uniqueTitles?.sort((a, b) => {
    if (a.toLocaleLowerCase() < b.toLocaleLowerCase()) {
      return -1;
    }
    if (a.toLocaleLowerCase() > b.toLocaleLowerCase()) {
      return 1;
    }
    return 0;
  });

  const dataProccessed: RowWithTitleProccessed[] = orderedTitles?.map(
    (title) => {
      const dataFiltered = data?.reportResult?.filter(
        (d) => d[titleName] === title
      );
      return {
        title,
        data: dataFiltered,
      };
    }
  );

  return (
    <View style={{ flexDirection: 'column' }}>
      {dataProccessed?.map((d) => {
        return (
          <View style={{ flexDirection: 'column' }} wrap={false}>
            <View style={styles.rowtitle}>
              <Text style={{ ...styles.text, width: `100%` }}>
                {isValueValid(d.title) ? d.title : ' '}
              </Text>
            </View>
            {d.data?.map((dta) => (
              <View style={styles.section}>
                {order?.map((item, index) => (
                  <Text
                    style={{ ...styles.text, width: `${colWidth[index]}%` }}
                  >
                    {applyFunctionIfAvailable(item, dta)}
                  </Text>
                ))}
              </View>
            ))}
          </View>
        );
      })}
    </View>
  );
};
const RowWithoutTitle = ({
  data,
  order,
  colWidth,
}: {
  data: PdfDocumentProps;
  order: Column[];
  colWidth: number[];
}): React.ReactElement | null => {
  if (!data || !order) return null;
  return (
    <View>
      {data?.reportResult?.map((d) => {
        return (
          <View style={styles.section} wrap={false}>
            {order?.map((item, index) => (
              <Text style={{ ...styles.text, width: `${colWidth[index]}%` }}>
                {isValueValid(d[item.key as keyof typeof d])
                  ? d[item.key as keyof typeof d]
                  : ' '}
              </Text>
            ))}
          </View>
        );
      })}
    </View>
  );
};
const StoredProcedureReportRow = ({
  columns,
  records,
}: ReportResultStoredProcedure) => {
  return (
    <View>
      {records
        .filter((_, index) => index < 500)
        ?.map((d, ind) => {
          return (
            <View style={styles.section} wrap={false} key={ind}>
              {columns?.map((_item, index) => (
                <Text style={{ ...styles.text }} key={index}>
                  {isValueValid(d?.columnValues?.[index])
                    ? d?.columnValues?.[index]
                    : ' '}
                </Text>
              ))}
            </View>
          );
        })}
    </View>
  );
};
