import styled from 'styled-components';
import SilverLight from 'assets/images/silverlight-logo.png';

const SilverLightImage = styled.img.attrs({
  src: SilverLight,
  alt: 'Microsoft SilverLight logo',
})`
  max-width: 88px;
`;

export default SilverLightImage;
