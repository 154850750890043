import styled from 'styled-components';
import SilverLight from 'assets/images/silverlight-darktheme-logo.png';

const SilverlightDarkThemeImage = styled.img.attrs({
  src: SilverLight,
  alt: 'Microsoft SilverLight logo',
})`
  max-width: 88px;
`;

export default SilverlightDarkThemeImage;
