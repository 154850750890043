import styled from 'styled-components';
import MsEdge from 'assets/images/msedge-logo.png';

const MsEdgeImage = styled.img.attrs({
  src: MsEdge,
  alt: 'Microsoft Edge logo',
})`
  max-width: 88px;
`;

export default MsEdgeImage;
