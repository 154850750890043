import { I18NEXT_DEBUG } from 'env';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import appI18NDefaults from './defaults';

// Could also pass in lng and translations immediately on app initialization
// instead of fetching + caching them. See react-i18next configuration options:
// https://www.i18next.com/overview/configuration-options

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: I18NEXT_DEBUG,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    // Test-specific settings
    ...(process.env.NODE_ENV === 'test' && {
      resources: {
        en: {},
      },
      debug: false,
      react: { useSuspense: false },
    }),

    ...appI18NDefaults,
  });

export default i18n;
