import Button from 'components/Button';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as BreadcrumbCaret } from 'assets/icons/vector.svg';
import { getCustomDomainContrastText } from 'styles/colours';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const StyledText = styled(Typography)`
  margin-right: 8px;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) =>
    props.theme.palette.type === 'light'
      ? getCustomDomainContrastText(props.theme.custom.domainColor)
      : props.theme.palette.text.primary};
`;

export const StyledButton = styled(Button)`
  height: 32px;
  && {
    padding: 8px 12px;
  }
`;

export const StyledGrid = styled(Grid)`
  line-height: 32px;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 16px;
  &:hover {
    cursor: pointer;
    background-color:${(props) => props.theme.custom.domainColor}
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverText: {
      padding: theme.spacing(2),
    },
  })
);

export const StyledBreadcrumbCaret = styled(BreadcrumbCaret)`
  color: ${(props) =>
    props.theme.palette.type === 'light'
      ? getCustomDomainContrastText(props.theme.custom.domainColor)
      : '#9e9e9e'};
`;
