import { QueryClient } from 'react-query';

export enum APIQueryKey {
  // General
  retrieveCurrentUserAccessibleDomains = 'retrieveCurrentUserAccessibleDomains',
  retrieveSiteEditComponentsById = 'retrieveSiteEditComponentsById',
  verifyPasswordStrength = 'verifyPasswordStrength',

  // Admin app
  retrieveSiteNumberList = 'retrieveSiteNumberList',
  getAssetGroupsForDomain = 'getAssetGroupsForDomain',
  getConvertedVolumetricValue = 'getConvertedVolumetricValue',
  getDisplayUnitsByUnitConversionMode = 'getDisplayUnitsByUnitConversionMode',
  getDomainNotes = 'getDomainNotes',
  getFtpDomains = 'getFtpDomains',
  getFileRtuDetails = 'getFileRtuDetails',
  getGenerateDataChannelMapPoints = 'getGenerateDataChannelMapPoints',
  getUserRolesForDomain = 'getUserRolesForDomain',
  getUserByUserId = 'getUserByUserId',
  retrieveRosterList = 'retrieveRosterList',
  retrieveGeoAreaList = 'retrieveGeoAreaList',
  getRosterDetails = 'getRosterDetails',
  getDataChannelDetailsById = 'getDataChannelDetailsById',
  get400SeriesPackets = 'get400SeriesPackets',
  getDataChannelEventsByDataChannelId = 'getDataChannelEventsByDataChannelId',
  getEventRuleGroupsByEventRuleGroupId = 'getEventRuleGroupsByEventRuleGroupId',
  getPublishedDomains = 'getPublishedDomains',
  getPublishedCommentsByDomainId = 'getPublishedCommentsByDomainId',
  getMessageTemplateList = 'getMessageTemplateList',
  getMessageTemplateDetails = 'getMessageTemplateDetails',
  getMessageTemplateContentOptions = 'getMessageTemplateContentOptions',
  getModbusCallHistory = 'getModbusCallHistory',
  getModbusCommLogSessionHistory = 'getModbusCommLogSessionHistory',
  retrieveModbusSessionQueue = 'retrieveModbusSessionQueue',
  retrieveModbusDiagnosticInformation = 'retrieveModbusDiagnosticInformation',
  retrieveModbusModemHubGroup = 'retrieveModbusModemHubGroup',
  retrieveModbusOutgoingInterfaces = 'retrieveModbusOutgoingInterfaces',
  retrieveModbusCarriers = 'retrieveModbusCarriers',
  retrieveModbusAIChannelConfig = 'retrieveModbusAIChannelConfig',
  getMetron2CallHistory = 'getMetron2CallHistory',
  getMetron2CommLogSessionHistory = 'getMetron2CommLogSessionHistory',
  getMetron2Configuration = 'getMetron2Configuration',
  getPollRtuStatus = 'getPollRtuStatus',
  retrieveMetronIsInSyncStatus = 'retrieveMetronIsInSyncStatus',
  retrieveGenralInfo = 'retrieveGenralInfo',
  retrieveCommInfo = 'retrieveCommInfo',
  retrieveConfigInfo = 'retrieveConfigInfo',
  retrieveRtu400AiChannelConfigList = 'retrieveRtu400AiChannelConfigList',
  retrieveRtu400DiChannelConfigList = 'retrieveRtu400DiChannelConfigList',
  retrieveRtu400ScalingMap = 'retrieveRtu400ScalingMap',
  retrieveRtu400AIChannelsInformation = 'retrieveRtu400AIChannelsInformation ',
  retrieveRtu400DIChannelsInformation = 'retrieveRtu400DIChannelsInformation',
  retrieveRtu400Status = 'retrieveRtu400Status',
  isRtu400InSync = 'isRtu400InSysc',
  isRtuSmsInSync = 'isRtuSmsInSync',
  retrieveDiagnosticInformation = 'retrieveDiagnosticInformation',
  retrievePortConfigurationList = 'retrievePortConfigurationList',
  retrievePortConfigurationDIList = 'retrievePortConfigurationDIList',
  retrieve400SeriesSyncAttemptsInfo = 'retrieve400SeriesSyncAttemptsInfo',
  retrieveModbusGeneralInfo = 'retrieveModbusGeneralInfo',
  retrieveModbusConfiguration = 'retrieveModbusConfiguration',
  retrieveModbusCommunication = 'retrieveModbusCommunication',
  retrieveModbusDiChannelSummaryList = 'retrieveModbusDiChannelSummaryList',
  retrieveModbusDiChannelConfiguration = 'retrieveModbusDiChannelConfiguration',
  retrieveDiChannelSummaryList = 'retrieveDiChannelSummaryList',
  retrieveModbusRtuAIchannels = 'retrieveModbusRtuAIchannels',
  retrieveModbusAiChannelConfiguration = 'retrieveModbusAiChannelConfiguration',
  retrieveSmsGeneralInfo = 'retrieveSmsGeneralInfo',
  retrieveRtuSmsIsInSyncConfig = 'retrieveRtuSmsIsInSyncConfig',
  retrieveSmsIChannelsInUse = 'retrieveSmsIChannelsInUse',
  retrieveSmsRtuCommunication = 'retrieveSmsRtuCommunication',
  retrieveSmsRtuConfiguration = 'retrieveSmsRtuConfiguration',
  retrieveSmsRtuAIchannels = 'retrieveSmsRtuAIchannels',
  retrieveSmsSyncAttemptsInfo = 'retrieveSmsSyncAttemptsInfo',
  retrieveSmsOutgoingInterfaces = 'retrieveSmsOutgoingInterfaces',
  retrieveRtuSmsSyncInfo = 'retrieveRtuSmsSyncInfo',
  retrieveSmsTimeCorrectionInfo = 'retrieveSmsTimeCorrectionInfo',
  retrieveMetronGeneralInformation = 'retrieveMetronGeneralInformation',
  retriveMetronCommunicationConfig = 'retriveMetronCommunicationConfig',
  retriveMetronRtuNotes = 'retriveMetronRtuNotes',
  retrieveMetronDiagnosticInformation = 'retrieveMetronDiagnosticInformation',
  retrieveRtuSessionQueue = 'retrieveRtuSessionQueue',
  retrieveMetronSyncAttemptsInfo = 'retrieveMetronSyncAttemptsInfo',
  retrieveMetronAIChannel = 'retrieveMetronAIChannel',
  retrieveMetronAIChannelsSummary = 'retrieveMetronAIChannelsSummary',
  retrieveMetronAIChannelsInUse = 'retrieveMetronAIChannelsInUse',
  retrieveMetronUomList = 'retrieveMetronUomList',
  getGeoAreaById = 'getGeoAreaById',
  getRtuChannelInfo = 'getRtuChannelInfo',
  getRtuCategory = 'getRtuCategory',
  getRawUnits = 'getRawUnits',
  getScaledUnits = 'getScaledUnits',
  getSmsRtuPackets = 'getSmsRtuPackets',
  getSiteInfoBySiteNumber = 'getSiteInfoBySiteNumber',
  getAuthenticationProviderForDomain = 'getAuthenticationProviderForDomain',
  retrieveAssetInfoRecords = 'retrieveAssetInfoRecords',
  retrieveAssetGroupInfoRecords = 'retrieveAssetGroupInfoRecords',
  retrieveAssetTreeInfoRecords = 'retrieveAssetTreeInfoRecords',
  retrieveDataChannelInfoRecords = 'retrieveDataChannelInfoRecords',
  retrieveDomainInfoRecords = 'retrieveDomainInfoRecords',
  retrieveProductRecords = 'retrieveProductRecords',
  retrieveRtuInfoRecords = 'retrieveRtuInfoRecords',
  rtuSearch = 'rtuSearch',
  retrieveRtuPollScheduleGroupRecords = 'retrieveRtuPollScheduleGroupRecords',
  retrieveSiteInfoRecords = 'retrieveSiteInfoRecords',
  retrieveTankDimensionInfoRecords = 'retrieveTankDimensionInfoRecords',
  retrievePollScheduleGroups = 'retrievePollScheduleGroups',
  retrieveHornerAnalogInputChannels = 'retrieveHornerAnalogInputChannels',
  retrieveHornerGeneralInformation = 'retrieveHornerGeneralInformation',
  getHornerCallHistory = 'getHornerCallHistory',
  getHornerCommLogSessionHistory = 'getHornerCommLogSessionHistory',
  getHornerDetailRecordTemplateList = 'getHornerDetailRecordTemplateList',
  getHornerTemplateList = 'getHornerTemplateList',
  retrieveHornerTransactionChannels = 'retrieveHornerTransactionChannels',
  retrieveHornerDiagnosticInformation = 'retrieveHornerDiagnosticInformation',
  retrieveHornerSessionQueue = 'retrieveHornerSessionQueue',
  retrieveRtuFileGeneralInformation = 'retrieveRtuFileGeneralInformation',
  retrieveRtuFileAnalogInputChannelsSummary = 'retrieveRtuFileAnalogInputChannelsSummary',
  retrieveCustomerName = 'retrieveCustomerName',
  // Operations App
  getAssetGroups = 'getAssetGroups',
  getAssetTreeNodeInfoListRootByDomain = 'getAssetTreeNodeInfoListRootByDomain',
  getDomainTags = 'getDomainTags',
  generateNewProblemReport = 'generateNewProblemReport',
  getUserWatchList = 'getUserWatchList',
  getProblemReportDetails = 'getProblemReportDetails',
  getProblemReportEmailAddresses = 'getProblemReportEmailAddresses',
  getProblemReportMessageTemplate = 'getProblemReportMessageTemplate',
  getProblemReportMessageTemplateOptions = 'getProblemReportMessageTemplateOptions',
  getProblemReportList = 'getProblemReportList',
  getProblemReportTags = 'getProblemReportTags',
  getRtuNotes = 'getRtuNotes',
  getSensorCalibration = 'getSensorCalibration',
  getTankSetup = 'getTankSetup',
  getUsageRateSummaryReport = 'getUsageRateSummaryReport',
  retrieveAssetNavigationList = 'retrieveAssetNavigationList',
  retrieveNavigatorWatchList = 'retrieveNavigatorWatchList',
  retrieveScheduledDeliveries = 'retrieveScheduledDeliveries',
  retrieveDeliveryScheduleList = 'retrieveDeliveryScheduleList',
  retrieveScheduledDeliveriesAndForecast = 'retrieveScheduledDeliveriesAndForecast',
  refreshDeliveryScheduleListAsset = 'refreshDeliveryScheduleListAsset',
  getUomGroupType = 'getUomGroupType',
  deliveryScheduleCalculateDeliveryScheduleListDayTotals = 'deliveryScheduleCalculateDeliveryScheduleListDayTotals',
  // Asset Details
  getLocationReadings = 'getLocationReadings',
  getLastKnownLocation = 'getLastKnownLocation',
  retrieveAssetDetailEventRules = 'retrieveAssetDetailEventRules',
  getDataChannelEventRuleRosters = 'getDataChannelEventRuleRosters',
  getRtuPollStatus = 'getRtuPollStatus',

  // Freezers App
  retrieveFreezerSites = 'retrieveFreezerSites',
  retrieveSiteDetails = 'retrieveSiteDetails',
  retrieveFreezerChartDetails = 'retrieveFreezerChartDetails',
  getDefaultChartList = 'getDefaultChartList',
  getFreezerDetails = 'getFreezerDetails',
  getDataChannelsByAssetId = 'getDataChannelsByAssetId',
  getTagListByAssetSubTypeId = 'getTagListByAssetSubTypeId',
  getFreezerChart = 'getFreezerChart',
  getDefaultChart = 'getDefaultChart',
  getChartsByAssetId = 'getChartsByAssetId',
  getBulkTankTagsBySiteId = 'getBulkTankTagsBySiteId',
  getHistoricalReadingsByBulkTankTagId = 'getHistoricalReadingsByBulkTankTagId',

  // Reports
  retrievePresetReports = 'retrievePresetReports',
  retrievePresetReportQueries = 'retrievePresetReportQueries',
  // Training App
  getTrainingVideos = 'getTrainingVideos',
}

export const queryClient = new QueryClient({
  defaultOptions: {
    // Queries defaults
    // https://react-query.tanstack.com/reference/useQuery
    queries: {
      // Don't retry failed queries by default
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});
