import styled from 'styled-components';
import Img from 'assets/images/dolv3-edge-instructions/3d.png';

const enableReloadSettingsRestartImage = styled.img.attrs({
  src: Img,
  alt: 'Restart your browser',
})`
  max-width: 820px;
`;

export default enableReloadSettingsRestartImage;
