import React from 'react';
import styled from 'styled-components';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

interface PageCountProps extends TypographyProps {
  bold?: boolean;
}

const StyledPageCount = styled((props: PageCountProps) => {
  const { bold, ...restProps } = props;
  return <Typography {...restProps} />;
})<PageCountProps>`
  && {
    font-size: ${(props) => props.theme.custom.fontSize?.commonFontSize};
    font-weight: ${(props) => (props.bold ? '500' : '400')};
    color: ${(props) => props.theme.palette.type === 'dark' && 'white'};
  }
`;

const PageCount = (props: PageCountProps) => {
  return <StyledPageCount aria-label="Item count" {...props} />;
};

export default PageCount;
