import styled from 'styled-components';
import Img from 'assets/images/dolv3-edge-instructions/4a.png';

const navigateToDolv3Image = styled.img.attrs({
  src: Img,
  alt: 'https://dolv3.dataonline.com',
})`
  max-width: 820px;
`;

export default navigateToDolv3Image;
