import { ApiService } from 'api/admin/ApiService';
import { useMutation } from 'react-query';

const useDeletePresetReports = () => {
  return useMutation((presetReportId: number) => {
    return ApiService.PresetReportService.presetReport_DeleteReport(
      presetReportId
    );
  });
};
export default useDeletePresetReports;
