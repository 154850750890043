import { buildCommonRoutesFromBasePath } from 'routes-config';

const reportsBase = '/reports';
const newReport = '/home';

const commonRoutes = buildCommonRoutesFromBasePath(reportsBase);

const routes = {
  base: reportsBase,
  ...commonRoutes,
  reports: {
    list: `${reportsBase}/list`,
  },
  newReport: {
    home: `${reportsBase}${newReport}`,
    quickAssetCreate: `${reportsBase}${newReport}/quick-asset-create`,
  },
};

export const tabsMap = {
  [routes.reports.list]: routes.reports.list,
  [routes.newReport.home]: routes.newReport.home,
};

export default routes;
