import { buildCommonRoutesFromBasePath } from 'routes-config';

const opsBase = '/ops';
const assetNavigator = '/asset-navigator';
const assetSummary = '/asset-summary';
const schedule = '/schedule';
const dashboardHome = '/dashboard';
const events = '/events';
const problemReports = '/problem-reports';
const bell = '/bell';
const problemReportId = 'problemReportId';

export const AssetId = 'assetId';
export const DataChannelId = 'dataChannelId';

const commonRoutes = buildCommonRoutesFromBasePath(opsBase);

const routes = {
  base: opsBase,
  ...commonRoutes,
  assetMap: {
    list: `${opsBase}/asset-map`,
  },
  dashboard: {
    home: `${opsBase}${dashboardHome}`,
    list: `${opsBase}${dashboardHome}/list`,
  },
  assetNavigator: {
    list: `${opsBase}${assetNavigator}`,
    detail: `${opsBase}${assetNavigator}/:${AssetId}`,
    detailQuickEditEvents: `${opsBase}${assetNavigator}/:${AssetId}/detail/quick-edit-events`,
  },
  assetSummary: {
    list: `${opsBase}${assetSummary}`,
    listVersion2: `${opsBase}${assetSummary}/v2`,
    detail: `${opsBase}${assetSummary}/:${AssetId}/detail`,
    detailQuickEditEvents: `${opsBase}${assetSummary}/:${AssetId}/detail/quick-edit-events`,
    create: `${opsBase}${assetSummary}/create`,
  },
  schedule: {
    list: `${opsBase}${schedule}`,
  },
  events: {
    list: `${opsBase}${events}`,
    create: `${opsBase}${events}/create`,
    detail: `${opsBase}${events}/:eventId`,
  },
  problemReports: {
    list: `${opsBase}${problemReports}`,
    create: `${opsBase}${problemReports}/:${DataChannelId}/create`,
    edit: `${opsBase}${problemReports}/:${problemReportId}/edit`,
  },
  bell: {
    list: `${opsBase}${bell}`,
    create: `${opsBase}${bell}/create`,
  },
};

export default routes;
export const tabsMap = {
  [routes.assetNavigator.list]: routes.assetNavigator.list,
  [routes.assetSummary.list]: routes.assetSummary.list,
  [routes.schedule.list]: routes.schedule.list,
  [routes.assetMap.list]: routes.assetMap.list,
  [routes.events.list]: routes.events.list,
  [routes.problemReports.list]: routes.problemReports.list,
  [routes.bell.list]: routes.bell.list,
  [routes.dashboard.home]: routes.dashboard.home,
};
