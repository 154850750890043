import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { enqueueSnackbar } from 'redux-app/modules/app/actions';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import { usePagination as useMuiPagination } from '@material-ui/lab/Pagination';
import {
  PagingResponseModelOfListOfPresetReportDto,
  ListSortDirection,
  PresetReportDto,
  UserPermissionType,
} from 'api/admin/api';
import { APIQueryKey } from 'api/react-query/helpers';
import {
  selectUserId,
  selectHasPermission,
} from 'redux-app/modules/user/selectors';
import { AccessType } from 'types';
import { selectActiveDomainId } from 'redux-app/modules/app/selectors';
import {
  Column,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useGlobalFilter,
} from 'react-table';
import { formatSearchText } from 'utils/api/helpers';
import SearchCloudIcon from 'components/icons/SearchCloudIcon';
import MessageBlock from 'components/MessageBlock';
import LargeBoldDarkText from 'components/typography/LargeBoldDarkText';
import BoxWithOverflowHidden from 'components/BoxWithOverflowHidden';
import TransitionErrorMessage from 'components/common/animations/TransitionErrorMessage';
import TransitionLoadingSpinner from 'components/common/animations/TransitionLoadingSpinner';
import DarkFadeOverlay from 'components/DarkFadeOverlay';
import PageIntroWrapper from 'components/layout/PageIntroWrapper';
import TableCellCheckbox from 'components/forms/styled-fields/TableCellCheckbox';
import GenericTableActionsAndPagination from 'components/GenericTableActionsAndPagination';
import GenericDataTable from 'components/GenericDataTable';
import { toggleAllSelectedRows, toggleOneSelectedRow } from 'utils/ui/deletion';
import PageIntro from './components/PageIntro';
import TableOptions from './components/TableOptions';

import {
  useRetrievePresetReports,
  OptionsProps,
} from '../../hooks/useRetrievePresetReports';
import useDeletePresetReports from '../../hooks/useDeletePresetReports';

const recordsDefault: PresetReportDto[] = [];

interface RtuPresetReportsTableData extends PresetReportDto {
  isSelected?: boolean;
}

const ReportsList = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);
  const domainId = useSelector(selectActiveDomainId);

  const [
    apiResponse,
    setApiResponse,
  ] = useState<PagingResponseModelOfListOfPresetReportDto | null>(null);

  // Filter by
  const [filterTextValue, setFilterTextValue] = useState('');

  // Pagination
  const [pageSize] = useState(50);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState<number | undefined>();

  const deletePresetReports = useDeletePresetReports();
  const hasPermission = useSelector(selectHasPermission);

  const canDelete = hasPermission(
    UserPermissionType.ReportAccess,
    AccessType.Delete
  );

  const hiddenPermissionColumns = canDelete ? [] : ['selection'];

  const hiddenColumns = React.useMemo(() => [...hiddenPermissionColumns], [
    hiddenPermissionColumns.join(','),
  ]);

  const records = apiResponse?.result || recordsDefault;

  const [selectedRows, setSelectedRows] = useState<
    Record<string, PresetReportDto>
  >({});

  const data = React.useMemo(() => [...records], [records, selectedRows]);
  const columns: Column<RtuPresetReportsTableData>[] = React.useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ rows: _rows }) => {
          const selectableRows = _rows.filter(
            (row) => row.original.presetReportId
          );

          const areAllRowsSelected =
            !!selectableRows.length &&
            selectableRows.every(
              (row) => selectedRows[row.original.presetReportId!]
            );
          return (
            <TableCellCheckbox
              onChange={() => {
                const newSelectedRows = toggleAllSelectedRows(
                  selectedRows,
                  selectableRows,
                  'presetReportId'
                );

                setSelectedRows(newSelectedRows);
              }}
              checked={areAllRowsSelected}
              disabled={!selectableRows.length}
            />
          );
        },
        accessor: 'presetReportId',
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <TableCellCheckbox
              onChange={() => {
                setSelectedRows((prevSelectedRows) => {
                  const newSelectedRows = toggleOneSelectedRow(
                    prevSelectedRows,
                    row,
                    'presetReportId'
                  );

                  return newSelectedRows;
                });
              }}
              checked={!!selectedRows[row.original.presetReportId!] || false}
            />
          );
        },
      },
      {
        accessor: 'name',
        Header: t('ui.common.name', 'Name') as string,
      },
      {
        accessor: 'type',
        Header: t('ui.reports.type', 'Type') as string,
        disableSortBy: true,
      },
      {
        Header: t(
          'ui.reports.includesubdomain',
          'Include Subdomains'
        ) as string,
        disableSortBy: true,
        accessor: (row: PresetReportDto) => {
          if (row?.includeSubDomains) return t('ui.common.yes', 'Yes');

          return t('ui.common.no', 'No');
        },
      },
      {
        accessor: 'assetGroupName',
        Header: t('ui.reports.assetgroup', 'Asset Group') as string,
        disableSortBy: true,
      },
      {
        Header: t('ui.reports.outputtype', 'Output Type') as string,
        disableSortBy: true,
        accessor: (row: PresetReportDto) => {
          if (
            row?.additionalScheduleInfoList &&
            row?.additionalScheduleInfoList?.length > 0
          ) {
            if (row?.additionalScheduleInfoList?.length > 1) {
              return t('ui.reports.multipleinfo', 'Multiple');
            }
            return row?.additionalScheduleInfoList[0].outputType;
          }

          return '';
        },
      },
      {
        Header: t('ui.reports.sendtype', 'Send Type') as string,
        disableSortBy: true,
        accessor: (row: PresetReportDto) => {
          if (
            row?.additionalScheduleInfoList &&
            row?.additionalScheduleInfoList?.length > 0
          ) {
            if (row?.additionalScheduleInfoList?.length > 1) {
              return t('ui.reports.multipleinfo', 'Multiple');
            }
            return row?.additionalScheduleInfoList[0].sendType;
          }

          return '';
        },
      },
      {
        Header: t('ui.reports.sendat', 'Send At') as string,
        disableSortBy: true,
        accessor: (row: PresetReportDto) => {
          if (
            row?.additionalScheduleInfoList &&
            row?.additionalScheduleInfoList?.length > 0
          ) {
            if (row?.additionalScheduleInfoList?.length > 1) {
              return t('ui.reports.multipleinfo', 'Multiple');
            }
            return `${row?.additionalScheduleInfoList[0].reportSendFrequency} at ${row?.additionalScheduleInfoList[0].reportSendTime}`;
          }

          return '';
        },
      },
      {
        Header: t('ui.reports.schedulecount', 'Schedule Count') as string,
        disableSortBy: true,
        accessor: (row: PresetReportDto) => {
          if (
            row?.additionalScheduleInfoList &&
            row?.additionalScheduleInfoList?.length > 0
          ) {
            return row?.additionalScheduleInfoList.length;
          }

          return '';
        },
      },
      {
        Header: t('ui.reports.sendto', 'Send To') as string,
        disableSortBy: true,
        accessor: (row: PresetReportDto) => {
          if (
            row?.additionalScheduleInfoList &&
            row?.additionalScheduleInfoList?.length > 0
          ) {
            if (row?.additionalScheduleInfoList?.length > 1) {
              return t('ui.reports.multipleinfo', 'Multiple');
            }
            if (
              row?.additionalScheduleInfoList[0].sendTo?.split(';') &&
              row?.additionalScheduleInfoList[0].sendTo?.split(';').length > 1
            ) {
              return `${
                row?.additionalScheduleInfoList[0].sendTo?.split(';').length
              } Users`;
            }
            return `${
              row?.additionalScheduleInfoList[0].sendTo?.split(';').length
            } User`;
          }

          return '';
        },
      },
    ],
    [t, selectedRows]
  );

  const tableInstance = useTable<PresetReportDto>(
    {
      // @ts-ignore
      columns,
      // @ts-ignore
      data,
      initialState: {
        // Pagination
        pageSize,
        pageIndex: pageNumber,
        hiddenColumns,
      },
      // Grouping
      expandSubRows: true,
      // Sorting
      disableMultiSort: true,
      manualSortBy: true,
      // Pagination
      autoResetPage: true,
      pageIndex: pageNumber,
      manualPagination: true,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const {
    rows,
    state: { sortBy },
  } = tableInstance;

  const sortByColumnId = sortBy?.[0]?.id;
  const sortByColumnDirection = sortBy?.[0]?.desc;

  const rtuPollScheduleGroupRecordsApiOptions = {
    isCountRequired: true,
    pageNumber,
    pageSize,
    filterText: formatSearchText(filterTextValue, {
      addWildcardAsterisks: true,
    }),
    sortColumnName: sortByColumnId,
    sortDirectionTypeId: sortByColumnDirection
      ? ListSortDirection.Descending
      : ListSortDirection.Ascending,
    domainId,
    userId,
  };

  const rtuPollScheduleGroupRecordsApi = useRetrievePresetReports(
    (rtuPollScheduleGroupRecordsApiOptions as unknown) as OptionsProps,
    {
      keepPreviousData: true,
      onSuccess: (apiData) => {
        setSelectedRows({});
        setApiResponse(apiData);
        const totalRecords = apiData.paging?.totalCount || 0;
        setTotalRows(totalRecords);
        setPageCount(Math.ceil(totalRecords / pageSize));
      },
    }
  );

  const handleChangePage = (event: any, newPage: any) => {
    setPageNumber(newPage);
  };

  const { items } = useMuiPagination({
    showFirstButton: true,
    showLastButton: true,
    count: pageCount,
    page: pageNumber,
    onChange: handleChangePage,
  });

  const {
    isLoading,
    isError,
    isFetching,
    refetch,
  } = rtuPollScheduleGroupRecordsApi;

  const handleDeleteOne = async (reportId: number) => {
    try {
      await deletePresetReports.mutateAsync(reportId);
      queryClient.invalidateQueries(APIQueryKey.retrievePresetReports);
      dispatch(
        enqueueSnackbar({
          message: t(
            'ui.rtu.presetreportsuccessfullydeleted',
            'Report successfully deleted.'
          ),
          options: { variant: 'success' },
        })
      );
    } catch (e) {
      dispatch(
        enqueueSnackbar({
          message: t('ui.common.defaultError', 'An unexpected error occurred'),
          options: { variant: 'error' },
        })
      );
    }
  };

  const handleDeleteReports = (reportIdArray: string[]) => {
    reportIdArray.map((rId) => handleDeleteOne(Number(rId)));
  };

  const handleSearchBy = (valueFilter: string) => {
    setFilterTextValue(valueFilter);
  };

  return (
    <>
      <PageIntroWrapper>
        <PageIntro refetchRecords={refetch} />
      </PageIntroWrapper>

      <Box pb={1}>
        <TableOptions setGlobalFilter={handleSearchBy} />
      </Box>

      <Fade
        in={!isLoading && !isError && !isFetching && rows.length === 0}
        unmountOnExit
      >
        <div>
          {!isLoading && !isError && !isFetching && rows.length === 0 && (
            <MessageBlock>
              <Box m={2}>
                <SearchCloudIcon />
              </Box>
              <LargeBoldDarkText>
                {t('ui.reports.empty', 'No Reports Setup')}
              </LargeBoldDarkText>
            </MessageBlock>
          )}
        </div>
      </Fade>
      <BoxWithOverflowHidden pt={0} pb={8}>
        <TransitionLoadingSpinner
          in={isLoading || (tableInstance.rows.length === 0 && isFetching)}
        />
        <TransitionErrorMessage in={!isLoading && !!isError} />

        <Fade
          in={!isLoading && !isError && tableInstance.rows.length > 0}
          style={{ height: '100%' }}
        >
          <Box height="100%" display="flex" flexDirection="column">
            <Box>
              <GenericTableActionsAndPagination
                totalRows={totalRows}
                pageIndex={pageNumber - 1}
                shouldShowDeleteAction={canDelete}
                shouldDisableDeleteAction={
                  !Object.values(selectedRows).some((_) => _)
                }
                actions={{
                  deleteSelected: () =>
                    handleDeleteReports(Object.keys(selectedRows)),
                }}
                pageSize={pageSize}
                align="center"
                items={items}
              />
            </Box>
            <Box py={1} height="100%">
              <DarkFadeOverlay darken={isFetching} height="100%">
                <GenericDataTable<PresetReportDto>
                  tableInstance={tableInstance}
                  disableActions={isFetching}
                  tableAriaLabelText="reports table"
                  columnIdToAriaLabel={(id) => id}
                  isRecordDisabled={() => false}
                  getColumnWidth={() => 100}
                  handleRowClick={() => {}}
                  minWidth={768}
                  TableProps={{ stickyHeader: true }}
                  TableContainerProps={{
                    style: {
                      maxHeight: '100%',
                    },
                  }}
                />
              </DarkFadeOverlay>
            </Box>
          </Box>
        </Fade>
      </BoxWithOverflowHidden>
    </>
  );
};

export default ReportsList;
