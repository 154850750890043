import {
  SortDirectionEnum,
  PagingResponseModelOfListOfPresetReportDto,
} from 'api/admin/api';
import { ApiService } from 'api/admin/ApiService';
import { APIQueryKey } from 'api/react-query/helpers';
import { useQuery, UseQueryOptions } from 'react-query';

export interface OptionsProps {
  isCountRequired?: boolean | undefined;
  userId?: string | undefined;
  domainId?: string | undefined;
  pageNumber?: number | undefined;
  pageSize?: number | undefined;
  filterText?: string | null | undefined;
  sortColumnName?: string | null | undefined;
  sortDirectionTypeId?: SortDirectionEnum | null | undefined;
}

export const useRetrievePresetReports = (
  filterOptions: OptionsProps,
  options?: UseQueryOptions<PagingResponseModelOfListOfPresetReportDto>
) => {
  const {
    isCountRequired,
    userId,
    domainId,
    pageNumber,
    pageSize,
    filterText,
    sortColumnName,
    sortDirectionTypeId,
  } = filterOptions;
  return useQuery(
    [APIQueryKey.retrievePresetReports, filterOptions],
    () =>
      ApiService.PresetReportService.presetReport_RetrievePresetReports(
        isCountRequired,
        userId,
        domainId,
        pageNumber,
        pageSize,
        filterText,
        sortColumnName,
        sortDirectionTypeId
      ),
    options
  );
};
