import { ApiService } from 'api/admin/ApiService';
import { APIQueryKey } from 'api/react-query/helpers';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { selectActiveDomain } from 'redux-app/modules/app/selectors';
import { selectUserId } from 'redux-app/modules/user/selectors';

const retrieveNavigatorWatchList = () => {
  return ApiService.AssetsService.assets_RetrieveNavigatorWatchList();
};

export const useRetrieveNavigatorWatchList = (isEnabled?: boolean) => {
  const activeDomain = useSelector(selectActiveDomain);
  const currentDomainId = activeDomain?.domainId;

  const currentUserId = useSelector(selectUserId);

  const request = { userId: currentUserId, domainId: currentDomainId! };

  return useQuery(
    [APIQueryKey.retrieveNavigatorWatchList, request],
    () => retrieveNavigatorWatchList(),
    {
      enabled: !!request && isEnabled,
      cacheTime: 1000 * 60 * 2,
      staleTime: 1000 * 60 * 2,
    }
  );
};
