import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

const LargeBoldDarkText = styled(Typography)`
  && {
    font-size: 18px;
    font-weight: 500;
  }
`;

export default LargeBoldDarkText;
